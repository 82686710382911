import BackgroundBlock from "../components/background-block"
import ContactMDX from "../content/contact"
import Layout from "../components/layout"
import React from "react"
import styled from "@emotion/styled"

export default () => {
  return (
    <Layout>
      <StyledBlock src="purchase-bg" repeat="repeat" size="contain">
        <ContactMDX />
      </StyledBlock>
    </Layout>
  )
}

const StyledBlock = styled(BackgroundBlock)`
  .content-section {
    padding-bottom: 147px !important;
    padding-top: 168px !important;

    h1, form, p {
      text-align: left;
      width:100%;
    }

    @media (max-width: 768px) {
      padding-bottom: 70px !important;
      padding-top: 84px !important;
    }
  }

  .contact-info {
    align-self: flex-end;

    p {
      color: #4c87a5;
      font-size: 17px;
      font-weight: 500;
      line-height: 21px;
      text-align: right;
    }
  }
`;
